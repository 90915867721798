.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.App-growOnHover { transition: all .2s ease-in-out; }
.App-growOnHover:hover { transform: scale(1.03); }

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

button:focus {
    outline: 0;
}

h1 {
  line-height: 1.4em;
  margin-bottom: 0.4em;
}